import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {EpisodeListResponse} from '../models/media/episode-list-response';

import {Episode} from '../models/media/episode';
import {BaseClient} from './base-client.service';

@Injectable()
export class EpisodeClient extends BaseClient {

  public getBaseEndpoint() {
    return this.baseEndpoint + '/episode';
  }

  public list(): Observable<EpisodeListResponse> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.get<EpisodeListResponse>(this.getBaseEndpoint(),
        {headers: this.getHeaders(token)});
    }));
  }

  public show(id: string): Observable<Episode> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.get<Episode>(this.getBaseEndpoint() + '/' + id,
        {headers: this.getHeaders(token)});
    }));
  }

  public store(formData: FormData): Observable<Episode> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<Episode>(this.getBaseEndpoint(), formData,
        {headers: this.getHeaders(token, false)});
    }));
  }

  public update(id, formData: FormData): Observable<Episode> {
    formData.append('_method', 'PUT');
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<Episode>(this.getBaseEndpoint() +  '/' + id, formData,
        {headers: this.getHeaders(token, false)});
    }));
  }

  public delete(id): Observable<any> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.delete<any>(this.getBaseEndpoint() + '/' + id,
        {headers: this.getHeaders(token)});
    }));
  }
}
