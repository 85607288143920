import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {AuthorListResponse} from '../models/author/author-list-response';

import {Author} from '../models/author/author';
import {BaseClient} from './base-client.service';

@Injectable()
export class AuthorClient extends BaseClient {

  public getBaseEndpoint() {
    return this.baseEndpoint + '/authors';
  }

  public list(): Observable<AuthorListResponse> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.get<AuthorListResponse>(this.getBaseEndpoint(),
        {headers: this.getHeaders(token)});
    }));
  }

  public show(id: string): Observable<Author> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.get<Author>(this.getBaseEndpoint() + '/' + id,
        {headers: this.getHeaders(token)});
    }));
  }

  public store(formData: FormData): Observable<Author> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<Author>(this.getBaseEndpoint(), formData,
        {headers: this.getHeaders(token, false)});
    }));
  }

  public update(id, formData: FormData): Observable<Author> {
    formData.append('_method', 'PUT');
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<Author>(this.getBaseEndpoint() +  '/' + id, formData,
        {headers: this.getHeaders(token, false)});
    }));
  }

  public delete(id): Observable<any> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.delete<any>(this.getBaseEndpoint() + '/' + id,
        {headers: this.getHeaders(token)});
    }));
  }

  public all(scope): Observable<Array<Author>> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.get<Array<Author>>(this.getBaseEndpoint() + '?pagination=0&scope='+scope,
        {headers: this.getHeaders(token)});
    }));
  }
}
