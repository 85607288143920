import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

import {BaseClient} from './base-client.service';
import {UserListResponse} from '../models/user/user-list-response';
import {User} from '../models/user/user';
import {Role} from '../models/user/role';
import { UserStats, UserStatsModel } from '../models/user/user-stats';

@Injectable()
export class CustomerClient extends BaseClient {
  
  public getBaseEndpoint() {
    return this.baseEndpoint + '/customers';
  }

  public getUserBaseEndpoint() {
    return this.baseEndpoint + '/users';
  }

  public getUserBaseEndpointV2() {
    return this.baseEndpointV2 + '/user';
  }

  public list(): Observable<UserListResponse> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.get<UserListResponse>(this.getUserBaseEndpoint(),
        {headers: this.getHeaders(token)});
    }));
  }

  public show(id: string): Observable<User> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.get<User>(this.getUserBaseEndpoint() + '/' + id,
        {headers: this.getHeaders(token)});
    }));
  }

  public stats(formData: FormData): Observable<any> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<UserStatsModel>(this.baseEndpoint + '/getUserStats' ,formData,
      {headers: this.getHeaders(token, false)});
    }));
  }

  public store(formData: FormData): Observable<User> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<User>(this.getUserBaseEndpoint(), formData,
        {headers: this.getHeaders(token, false)});
    }));
  }

  public update(id, formData: FormData): Observable<User> {
    formData.append('_method', 'PUT');
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<User>(this.getUserBaseEndpoint() + '/' + id, formData,
        {headers: this.getHeaders(token, false)});
    }));
  }

  public delete(id,formData: FormData): Observable<any> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<any>(this.getUserBaseEndpointV2() + '/block/' + id,JSON.stringify(formData) ,
        {headers: this.getHeaders(token)});
    }));
  }

  public update_trial(formData: FormData): Observable<any> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.post<any>(this.baseEndpoint + '/extendTrial' ,formData ,
        {headers: this.getHeaders(token,false)});
    }));
  }

  public roles(): Observable<Array<Role>> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.get<Array<Role>>(this.getUserBaseEndpoint() + '/roles',
        {headers: this.getHeaders(token)});
    }));
  }

  public all(): Observable<Array<User>> {
    return this.authService.getToken().pipe(switchMap((token) => {
      return this.http.get<Array<User>>(this.getUserBaseEndpoint() + '?pagination=0',
        {headers: this.getHeaders(token)});
    }));
  }

}
